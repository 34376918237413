<template>
  <div>
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
  </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'EventDetails',

  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const state = useStore()

    onMounted(() => {
      state.dispatch('loadEvent', props.id).catch((error) => {
        console.log({ error })
      })
    })

    const event = computed(() => state.getters['getEvent'])

    return {
      event
    }
  }
}
</script>
