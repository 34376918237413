import axios from 'axios'
const api = 'http://localhost:3000'

const apiClient = axios.create({
  baseURL: api,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  getEvents(params) {
    return apiClient.get('/events', {
      params: params
    })
  },
  getEvent(id) {
    return apiClient.get('/events/' + id)
  },
  createEvent(event) {
    return apiClient.post('/events', event)
  },
  deleteEvent(id) {
    return apiClient.put('/events/' + id)
  }
}
