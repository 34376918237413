<template>
  <h1>Ooops! there was an error : </h1>
  <p>{{ error }}</p>
</template>
<script>

export default {
  name: 'ErrorDisplay',

  props: {
    error: {
      type: String,
      required: true
    }
  }
}
</script>
