import { createStore } from 'vuex';
import EventService from '@/services/EventService.js'

export default createStore({
  state: {
    user: 'Salma EL HAJRAOUI',
    event: {},
    events: [
      // {
      //   id: 123,
      //   category: 'animal welfare',
      //   title: 'Cat Adoption Day',
      //   description: 'Find your new feline friend at this event.',
      //   location: 'Meow Town',
      //   date: 'January 28, 2022',
      //   time: '12:00',
      //   organizer: 'Kat Laydee'
      // },
      // {
      //   id: 123,
      //   category: 'animal welfare',
      //   title: 'Cat Adoption Day',
      //   description: 'Find your new feline friend at this event.',
      //   location: 'Meow Town',
      //   date: 'January 28, 2022',
      //   time: '12:00',
      //   organizer: 'Kat Laydee'
      // },
    ]
  },
  mutations: {
    ADD_EVENT(state, event) {
      state.events.push(event)
    },
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_EVENT(state, event) {
      state.event = event
    }
  },
  actions: {
    async createEvent({ commit }, event) {
      const response = await EventService.createEvent(event)
      commit('ADD_EVENT', response.data)
    },
    async loadEvents({ commit }) {
      const response = await EventService.getEvents()
      commit('SET_EVENTS', response.data)
    },
    async loadEvent({ commit, state }, id) {
      const existingEvent = state.events.find(event => event.id === id)
      if (existingEvent) {
        commit('SET_EVENT', existingEvent)
      } else {
        const response = await EventService.getEvent(id)
        commit('SET_EVENT', response.data)
      }
    }
  },
  getters: {
    getEvents(state) {
      return state.events
    },
    getEvent(state) {
      return state.event
    }
  },
})
