<template>
  <!-- <div class="error" v-if="errored">
    <p>We're sorry, we're not able to to retrieve this information at the moment, please try back later OR NOT IT'S UP TO YOU !</p>
  </div> -->
  <div class="event">
    <h1>Events for goods</h1>
    <div v-if="loading">Loading...</div>
    <EventCard
      v-else
      v-for="event in events"
      :key="event.id"
      :event="event"
    />
  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue'
import EventCard from '@/components/EventCard.vue'
import { useStore } from 'vuex'

export default {
  name: 'EventList',

  components: {
    EventCard,
  },

  setup() {
    const store = useStore()
    const loading = ref(true)

    const getEvents = () => {
      store.dispatch('loadEvents').then(() => {
        loading.value = false
      }).catch((error) => {
        console.log(error)
      })
    }

    onMounted(() => {
      getEvents()
    })

    const events = computed(() => store.getters['getEvents'])

    return {
      loading,
      events,
      getEvents
    }
  }
}
</script>

<style scoped>
.event {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
